<template>
  <div v-if="show">
    <div v-if="pluses.length > 0"  @click="showPlus = !showPlus">
      <div class="cart_tr tr-mutiple add_sale">
        <div class="cart_td td-mutiple_title">
          <p class="mutiple_title txt-white">
            <span>加購</span>
          </p>
        </div>
        <div class="cart_td td-add_inner"  :class="{ active: showPlus }">
          <div class="cart_tr" v-for="(plus, i) in pluses" :key="`pluses_${i}`">
            <div class="cart_td td-add-product">
              <input type="checkbox"
                v-model="plus.selected"
                :disabled="$route.name !== 'CartInfo'"
                @input="selected(plus.selected, i, getType(2))"
                :name="`plus_${i}`" :id="`plus_${i}`">
              <label :for="`plus_${i}`">
                <span></span>
                <div class="add_icon">
                  <figure
                    v-bind:style="{ 'background-image': 'url(' + plus.cover + ')' }"></figure>
                </div>
                <div class="add_name">
                  <h6>{{plus.product_name}}</h6>
                  <!-- <small class="txt-greenlake">賠償費用大幅降低一半</small> -->
                </div>
              </label>
            </div>
            <div
              class="cart_td td-time center rwd-wifi" data-rwd="時間"
              v-if="plus.caculate_way === 1">
              <p>{{item.days}} 天</p>
            </div>
            <!-- 1 加購金額一致 2 否 -->
            <div
              class="cart_td td-prize center rwd-wifi"
              data-rwd="單價"
              v-if="plus.same_amount === 1">
              <div>
                <p>{{plus.plus_range}}/
                  <span v-if="plus.caculate_way === 1">
                    天
                  </span>
                  <span v-if="plus.caculate_way === 2">
                    個
                  </span>
                </p>
              </div>
            </div>
            <div
              class="cart_td td-prize center rwd-wifi"
              data-rwd="單價"
              v-else-if="plus.same_amount === 2">
              <div>
                <p>{{plus.plus_range}}/
                  <span v-if="plus.caculate_way === 1">
                    天
                  </span>
                  <span v-if="plus.caculate_way === 2">
                    個
                  </span>
                </p>
              </div>
            </div>
            <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                <div class="cart_number" data-max="10">
                  <div
                    v-if="$route.name === 'CartInfo' && !plus.selected"
                    class="minus" @click="minusItem(i, getType(2))">-</div>
                  <input id="cart_number001" class="count p" type="text" :value="plus.qty" readonly>
                  <div
                    v-if="$route.name === 'CartInfo' && !plus.selected"
                    class="plus" @click="plusItem(i, getType(2))">+</div>
                </div>
              </div>
            <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
              <div>
                <p>
                  <vue-numeric
                    read-only currency="$" separator="," :value="computedItemTotal(plus)">
                  </vue-numeric>
                </p>
              </div>
            </div>
            <div class="cart_td td-del"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="addServices.length > 0" @click="showAddServices = !showAddServices">
      <div class="cart_tr tr-mutiple add_sale">
        <div class="cart_td td-mutiple_title">
          <p class="mutiple_title txt-white">
            <span>附加服務</span>
          </p>
        </div>
        <div class="cart_td td-add_inner" :class="{ active: showAddServices }">
          <div class="cart_tr" v-for="(plus, i) in addServices" :key="`pluses_${i}`">
            <div class="cart_td td-add-product">
              <input type="checkbox"
                v-model="plus.selected"
                :disabled="$route.name !== 'CartInfo'"
                @input="selected(plus.selected, i, getType(3))"
                :name="`addServices_${i}`" :id="`addServices_${i}`">
              <label :for="`addServices_${i}`">
                <span></span>
                <div class="add_icon">
                  <figure
                    v-bind:style="{ 'background-image': 'url(' + plus.cover + ')' }"></figure>
                </div>
                <div class="add_name">
                  <h6>{{plus.product_name}}</h6>
                  <!-- <small class="txt-greenlake">賠償費用大幅降低一半</small> -->
                </div>
              </label>
            </div>
            <div
              class="cart_td td-time center rwd-wifi" data-rwd="時間"
              v-if="plus.caculate_way === 1">
              <p>{{item.days}} 天</p>
            </div>
            <!-- 1 加購金額一致 2 否 -->
            <div
              class="cart_td td-prize center rwd-wifi"
              data-rwd="單價"
              v-if="plus.same_amount === 1">
              <div>
                <p>{{plus.plus_range}}/
                  <span v-if="plus.caculate_way === 1">
                    天
                  </span>
                  <span v-if="plus.caculate_way === 2">
                    個
                  </span>
                </p>
              </div>
            </div>
            <div
              class="cart_td td-prize center rwd-wifi"
              data-rwd="單價"
              v-else-if="plus.same_amount === 2">
              <div>
                <p>{{plus.plus_range}}/
                  <span v-if="plus.caculate_way === 1">
                    天
                  </span>
                  <span v-if="plus.caculate_way === 2">
                    個
                  </span>
                </p>
              </div>
            </div>
            <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                <div class="cart_number" data-max="10">
                  <div
                    v-if="$route.name === 'CartInfo' && !plus.selected"
                    class="minus" @click="minusItem(i, getType(3))">-</div>
                  <input
                  id="cart_number001" class="count p" type="text" :value="plus.qty" readonly>
                  <div
                    v-if="$route.name === 'CartInfo' && !plus.selected"
                    class="plus" @click="plusItem(i, getType(3))">+</div>
                </div>
              </div>
            <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
              <div>
                <p>
                  <vue-numeric
                    read-only currency="$" separator="," :value="computedItemTotal(plus)">
                  </vue-numeric>
                </p>
              </div>
            </div>
            <div class="cart_td td-del"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="frees.length > 0"  @click="showFree = !showFree">
      <div
        v-for="(free, i) in frees" :key="`free_${i}`"
        class="cart_tr tr-mutiple add_gift">
        <div class="cart_td td-mutiple_title">
            <p class="mutiple_title txt-white">
                <span>贈品</span>
            </p>
        </div>
        <div class="cart_td td-add_inner"  :class="{ active: showFree }">
            <div class="cart_tr">
                <div class="cart_td td-add-product">
                  <input type="checkbox"
                    v-model="free.selected"
                    :disabled="$route.name !== 'CartInfo'"
                    @input="selected(free.selected, i, getType(1))"
                    :name="`frees_${i}`" :id="`frees_${i}`">
                  <label :for="`frees_${i}`">
                    <span></span>
                    <div class="add_icon">
                      <figure
                        v-bind:style="{ 'background-image': 'url(' + plus.cover + ')' }">
                      </figure>
                    </div>
                    <div class="add_name">
                      <h6>{{free.product_name}}</h6>
                      <!-- <small class="txt-greenlake">賠償費用大幅降低一半</small> -->
                    </div>
                  </label>
                </div>
                <div class="cart_td td-count center rwd-wifi" data-rwd="數量">
                    <p>{{free.free_limit}}</p>
                </div>
                <div class="cart_td td-total center rwd-wifi" data-rwd="小計">
                    <div>
                        <p>Free</p>
                    </div>
                </div>
                <div class="cart_td td-del"></div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import requestApi from '@/lib/http/index';

export default {
  name: 'CartPlus',
  props: ['item'],
  data() {
    return {
      show: false,
      showPlus: false,
      showAddServices: false,
      showFree: false,
      pluses: [],
      frees: [],
      addServices: [],
    };
  },
  methods: {
    getType(type) {
      let plusType;
      if (type === 2) {
        plusType = 'pluses';
      } else if (type === 3) {
        plusType = 'addServices';
      } else {
        plusType = 'frees';
      }
      return plusType;
    },
    async selected(selectedStatus, index, plusType) {
      const changeStatus = !selectedStatus;
      if (changeStatus) {
        let total;
        let qty;
        if (plusType === 'frees') {
          total = 0;
          qty = this[plusType][index].free_limit;
        } else {
          total = this.computedItemTotal(this[plusType][index]);
          qty = this[plusType][index].qty;
        }
        const formData = {
          member_id: this.user.id,
          cart_id: this.item.id,
          plus_event_id: this[plusType][index].id,
          qty,
          total,
          days: this.item.days ?? 0,
        };
        if (plusType === 'frees') {
          formData.price = 0;
        } else {
          formData.price = this[plusType][index].plus_range;
        }
        const { status, message } = await requestApi('cart.addPlusItem', formData);
        if (!status) {
          this[plusType][index].selected = false;
          this.$customSWAL({
            icon: 'error',
            title: '錯誤',
            text: message,
            confirmButtonText: '確定',
          });
        }
      } else {
        const formData = {
          member_id: this.user.id,
          cart_id: this.item.id,
          plus_event_id: this[plusType][index].id,
        };
        const { status, message } = await requestApi('cart.minusPlusItem', formData);
        if (!status) {
          this[plusType][index].selected = true;
          this.$customSWAL({
            icon: 'error',
            title: '錯誤',
            text: message,
            confirmButtonText: '確定',
          });
        }
      }
    },
    computedItemTotal(product) {
      let total = 0;
      if (product.same_amount === 1) {
        total = parseInt(product.plus_range, 10) * product.qty;
      } else {
        total = parseInt(product.plus_range, 10) * product.qty;
      }
      if (product.caculate_way === 1) {
        const days = this.item.days ?? 1;
        total *= days;
      }
      return total;
    },
    minusItem(v, plusType) {
      if (this[plusType][v].qty !== 1) {
        this[plusType][v].qty -= 1;
      } else {
        this[plusType][v].qty = 1;
      }
    },
    plusItem(v, plusType) {
      const plusCount = this[plusType][v].qty + 1;
      if (plusCount <= (this[plusType][v].purchase_limit)) {
        this[plusType][v].qty = plusCount;
      } else {
        this.$customSWAL({
          icon: 'info',
          title: '超過此加購品加購數量限制',
        });
      }
    },
    /* 取得商品之加購品/贈品/附加品 */
    async getProductPlusEvents() {
      const data = this.item;
      const result = await requestApi('promotion.getProductPlusEvents', {
        product_id: data.product_id,
        qty: data.qty,
        sub_total: data.total,
        days: data.days,
      });
      if (result.status) {
        this.show = true;
        const plus = [];
        const frees = [];
        const addServices = [];
        result.data.forEach((item) => {
          if (item.event_type === 1) {
            const plusItem = { ...item, selected: false };
            frees.push(plusItem);
          } else if (item.event_type === 2) {
            const plusItem = { ...item, qty: 1, selected: false };
            plus.push(plusItem);
          } else if (item.event_type === 3) {
            const plusItem = { ...item, qty: 1, selected: false };
            addServices.push(plusItem);
          }
        });
        this.pluses = plus;
        this.frees = frees;
        this.addServices = addServices;
        const getPlusItem = await requestApi('cart.getPlusItem', {
          member_id: this.user.id,
          cart_id: this.item.id,
        });
        if (getPlusItem.status) {
          getPlusItem.data.forEach((item) => {
            /* 已選贈品 */
            const freeIndex = this.frees.findIndex(
              (plusItem) => plusItem.id === item.plus_event_id,
            );
            if (freeIndex >= 0) {
              this.frees[freeIndex].qty = item.qty;
              this.frees[freeIndex].selected = true;
            }
            /* 已選加購品 */
            const index = this.pluses.findIndex((plusItem) => plusItem.id === item.plus_event_id);
            if (index >= 0) {
              this.pluses[index].qty = item.qty;
              this.pluses[index].selected = true;
            }
            /* 已選附加商品 */
            const addServiceIndex = this.addServices.findIndex(
              (plusItem) => plusItem.id === item.plus_event_id,
            );
            if (addServiceIndex >= 0) {
              this.addServices[addServiceIndex].qty = item.qty;
              this.addServices[addServiceIndex].selected = true;
            }
          });
        }
      } else {
        this.show = false;
      }
    },
  },
  watch: {
    computedPlusPrice(val) {
      this.$emit('plusTotalPrice', val);
    },
  },
  computed: {
    ...mapState([
      'user',
    ]),
    computedPlusPrice() {
      let total = 0;
      this.pluses.forEach((item) => {
        if (item.selected) {
          const itemTotal = this.computedItemTotal(item);
          total += itemTotal;
        }
      });
      this.addServices.forEach((item) => {
        if (item.selected) {
          const itemTotal = this.computedItemTotal(item);
          total += itemTotal;
        }
      });
      return total;
    },
  },
  mounted() {
    if (this.item) {
      this.getProductPlusEvents();
    }
  },
};
</script>
